<template>
  <div
    id="terms-and-conditions"
    class="grid h-screen"
    style="grid-template-rows: 1fr 45px"
  >
    <TopNavigation arrowAction="/" helpAction="/help" :hasLogo="true" />

    <iframe
      src="https://app.termly.io/document/terms-of-use-for-ecommerce/a331476d-7fff-43f2-a1d7-80c8d61ea8e2"
      class="h-full w-screen pb-4"
      style="margin-left: -20px"
      title="terms-and-conditions"
    ></iframe>

    <RouterLink to="/" replace class="terms-button"> Back</RouterLink>
  </div>
</template>

<script>
  import TopNavigation from "./TopNavigation.vue";

  export default {
    name: "TermsAndConditions",
    components: {
      TopNavigation,
    },
  };
</script>

<style scoped lang="scss">
  #terms-and-conditions {
    padding: 80px 20px 20px 20px;
  }

  .privacy-notice-text {
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
  }

  .terms-button {
    width: 200px;
    min-width: 200px;
    margin: 0 auto;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--main);
    background: var(--main);
    color: white;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 4px 0 0 #e6e6e6;
    transition: transform 100ms ease-in-out;
    text-decoration: none;

    &:active {
      transform: scale(0.99);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus {
      background-color: var(--main);
    }
  }

  .main-top-nav {
    background: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    z-index: 100000;
    padding: 15px;
  }
</style>
